// global.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomConfigurationService } from 'src/app/core/services/custom-configuration.service';
import { GlobalEventsService } from 'src/app/services/global/global-events-service';
import { PersonService } from './person.service';
import { UserService } from 'src/app/core/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyConfigurationService {
    constructor(
        private personService: PersonService,
        private customConfigurationService: CustomConfigurationService,
        private globalEventsService: GlobalEventsService,
        private userService: UserService,
        private router: Router
    ) {}
    defaultBranding = {
        primary_color: '#646567',
        secondary_color: '#DADADA',
        mouse_over: '#B00036',
        warning_color: '',
        primary_font: 'Arial',
        header_text_color: '#646567',
    };
    akPerson: any;
    fillBodyElement(branding) {
        const themeWrapper = document.querySelector('body');
        if (themeWrapper) {
            if (branding.primary_color) {
                themeWrapper.style.setProperty('--primary', branding.primary_color);
            }

            if (branding.secondary_color) {
                themeWrapper.style.setProperty('--secondary', branding.secondary_color);
            }

            if (branding.mouse_over) {
                themeWrapper.style.setProperty('--hover', branding.mouse_over);
            }

            if (branding.primary_font) {
                themeWrapper.style.setProperty('--primary-font', branding.primary_font);
            }

            if (branding.header_text_color) {
                themeWrapper.style.setProperty('--header-text-color', branding.header_text_color);
            }
            if (branding.logoUrl) {
                this.checkImageExists(branding.logoUrl).then((exists) => {
                    exists && this.globalEventsService.setLogo(branding.logoUrl);
                });
            }
        }
    }
    checkImageExists(imageUrl: string): Promise<boolean> {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = imageUrl;
        });
    }
    setCustomConfig(person, login = false): void {
        this.globalEventsService.setIsLoading(true);
        this.customConfigurationService.getCustomConfiguration$('companyConfig').subscribe({
            next: (conf) => {
                const company = person.roles.find((role) => role.roleType === 'EMPLOYEE').companyId;
                const customBranding = conf?.config[company]?.employee?.branding;

                const homePageCards = conf?.config[company]?.employee?.homePage?.cards;
                const homePageConfig = homePageCards
                    ? Object.keys(homePageCards).filter((key) => !!homePageCards[key].active)
                    : [];
                this.globalEventsService.setHomePageCards(homePageConfig);

                const features = conf?.config[company]?.employee?.features;

                const branding = customBranding ? customBranding : this.defaultBranding;

                if (!!features) this.globalEventsService.setHasCabinet(features.cabinet);
                sessionStorage.setItem('hasCabinet', features.cabinet ? 'true' : 'false');
                this.globalEventsService.setBranding(branding);
                this.fillBodyElement(branding);
                if (login) {
                    this.router.navigate(['/home']);
                }
                if (features.ak) {
                    const penum = person.personalInformation.penum;
                    const paging = { pageSize: 30 };
                    const filtering = { personNumber: penum };

                    this.personService.getAll(paging, {}, filtering).subscribe((persons) => {
                        const filteredPersons = persons.content.filter(
                            (person) => person.personalInformation.penum === penum
                        );
                        const akProfile = filteredPersons.filter((person) =>
                            person.roles.find((role) => role.foundationId === 'VAK')
                        );
                        if (akProfile.length > 0 && Object.keys(akProfile[0]).length) {
                            this.globalEventsService.setAkProfile(akProfile[0]);
                            this.globalEventsService.setHasAk(!!Object.keys(akProfile[0]).length);
                            const value = !!Object.keys(akProfile[0]).length;
                            sessionStorage.setItem('hasAk', value ? 'true' : 'false');
                            this.userService.setHasAK$(value);
                            this.globalEventsService.setIsLoading(false);
                        }
                    });
                    this.globalEventsService.setIsLoading(false);
                } else {
                    this.globalEventsService.setIsLoading(false);
                }
            },
            error: (err) => {
                this.fillBodyElement(this.defaultBranding);
                this.globalEventsService.setHasCabinet(false);
                this.globalEventsService.setHasAk(false);
                this.globalEventsService.setIsLoading(false);
            },
        });
    }
}
