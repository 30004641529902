import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomConfigurationService {
    get apiUrl(): string {
        return environment.clanDataApiUrl;
    }

    private hasCMS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private news: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public http: HttpClient) {}

    setCMS(value: boolean) {
        this.hasCMS.next(value);
    }

    setNews(value: boolean) {
        this.news.next(value);
    }

    getCustomConfiguration$(name: string): Observable<any> {
        const url = `${this.apiUrl}/custom-configs/getByName/${name}`;
        return this.http.get<any>(url);
    }

    getCMSConfig(): boolean {
        return this.hasCMS.value;
    }

    getNewsConfig(): boolean {
        return this.news.value;
    }
}
