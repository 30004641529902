import { Injectable } from '@angular/core';
import { get, union } from 'lodash-es';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
    BarChartData,
    BarChartDataItemValue,
    PensionFundInfo,
    PensionFundInfoCardDictionary,
    PensionFundInfoItem,
} from '../interfaces';
import { Insurances, PersonalInformation } from '../models/ClanMyData';
import { LocaleService } from './locale.service';
import { UserService } from './user.service';

@Injectable()
export class MyPensionService {
    private pensionFundsInfoData: BehaviorSubject<PensionFundInfo[]> = new BehaviorSubject<PensionFundInfo[]>([]);
    private infoCardsDictionary: BehaviorSubject<PensionFundInfoCardDictionary[]> = new BehaviorSubject<
        PensionFundInfoCardDictionary[]
    >([]);
    private infoCardsData: BehaviorSubject<PensionFundInfoItem[]> = new BehaviorSubject<PensionFundInfoItem[]>([]);
    private homePageInfoCards: BehaviorSubject<PensionFundInfoItem[]> = new BehaviorSubject<PensionFundInfoItem[]>([]);
    private selectedAgeNumber: number;
    selectedAge: BehaviorSubject<number> = new BehaviorSubject(1);
    pensionFundDataSubscription: Subscription = new Subscription();
    ageList: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);

    private barChartData: BehaviorSubject<BarChartData[]> = new BehaviorSubject<BarChartData[]>([]);

    constructor(private localeService: LocaleService, private userService: UserService) {
        this.setupLocaleChangeHandler();
        this.setupSelectedAgeChangeHandler();
        this.setupInsuranceDataHandler();
        this.ageList.next([]);
    }
    get $ageList(): Observable<number[]> {
        return this.ageList.asObservable();
    }

    get $barChartData(): Observable<BarChartData[]> {
        return this.barChartData.asObservable();
    }

    get $insurances(): Observable<Insurances[]> {
        return this.userService.$insurances;
    }

    get $pensionFundsInfoData(): Observable<PensionFundInfo[]> {
        return this.pensionFundsInfoData.asObservable();
    }

    get $infoCardsDictionary(): Observable<PensionFundInfoCardDictionary[]> {
        return this.infoCardsDictionary.asObservable();
    }

    get $homePageInfoCards(): Observable<PensionFundInfoItem[]> {
        return this.homePageInfoCards.asObservable();
    }

    private get $userData(): Observable<PersonalInformation> {
        return this.userService.$userData;
    }

    private get infoFieldsTranslate() {
        return this.localeService.getTranslate('cabinet.home.infoField');
    }

    private get $infoCardsData(): Observable<PensionFundInfoItem[]> {
        return this.infoCardsData.asObservable();
    }

    selectAge(selectedAge: number) {
        this.selectedAge.next(selectedAge);
    }

    private setupInsuranceDataHandler(): void {
        this.pensionFundDataSubscription.add(
            this.$insurances.subscribe((insurances) => {
                if(!insurances || insurances?.length === 0) return;
                this.setAgeListData(insurances);
                this.setPensionFundData(insurances);
                this.initBarChartData(insurances);
            })
        );
    }

    private setAgeListData(insurances: Insurances[]): void {
        const ageListNonSorted: number[] = [];
        const ageListSorted: number[] = [];
        if(!insurances || insurances?.length === 0) return;
        insurances.forEach((insurance) => {
            ageListNonSorted.push(
                ...Object.keys(insurance.benefits.retirement.projections)
                    .filter((key) => +key)
                    .map((key) => +key)
            );
        });
        if (ageListNonSorted.length) {
            ageListSorted.push(...union(ageListNonSorted).sort((a, b) => a - b));
            if (ageListSorted.length) {
                this.ageList.next(ageListSorted);
                this.selectAge(ageListSorted[7]);
            }
        }
    }

    private setPensionFundData(insurances?: Insurances[]): void {
        const pensionFundInfoData: PensionFundInfo[] = [];
        insurances.forEach((insurance) => {
            pensionFundInfoData.push({
                disabled: false,
                infoCardsVisibility: false,
                pensionFundName: insurance.foundationId,
                infoCards: this.setInfoCardsData(insurance),
                buttonType: 'green',
            });
        });

        if (pensionFundInfoData.length) {
            pensionFundInfoData[0].infoCardsVisibility = true;
        }

        this.pensionFundsInfoData.next(pensionFundInfoData);
    }

    private setInfoCardsData(insurance: Insurances): Observable<PensionFundInfoItem[]> {
        this.pensionFundDataSubscription.add(
            this.$infoCardsDictionary.subscribe((dictionary) => {
                const infoCards: PensionFundInfoItem[] = [];
                const homePageInfoCards: PensionFundInfoItem[] = [];
                dictionary.forEach((item) => {
                    if (item.key) {
                        if (item.key === 'monthlyPension') {
                            const pensionValue =
                                insurance.benefits.retirement.projections[this.selectedAge.value]?.pension;
                            if (pensionValue) {
                                const monthlyPension = {
                                    title: item.value,
                                    key: item.key,
                                    value: +(pensionValue / 12).toFixed(1),
                                    infoText: item.info,
                                    unit: 'CHF',
                                };
                                infoCards.push(monthlyPension);
                                homePageInfoCards.push(monthlyPension);
                            }
                        }
                        const valueFromPath = get(insurance, item.key) as number | string | undefined;
                        if (typeof valueFromPath !== 'undefined') {
                            if (
                                (item.key === 'salaries.insured' ||
                                    item.key === `benefits.retirement.projections.${this.selectedAge.value}.pension` ||
                                    item.key === `benefits.retirement.projections.${this.selectedAge.value}.capital`) &&
                                !homePageInfoCards.find((infoCard) => infoCard.key === item.key)
                            ) {
                                homePageInfoCards.push({
                                    title: item.value,
                                    value: valueFromPath,
                                    key: item.key,
                                    infoText: item.info,
                                    unit: item.unit,
                                });
                            }
                            infoCards.push({
                                title: item.value,
                                value: valueFromPath,
                                key: item.key,
                                infoText: item.info,
                                unit: item.unit,
                            });
                        }
                    }
                });
                this.infoCardsData.next(infoCards);
                this.homePageInfoCards.next(homePageInfoCards);
            })
        );
        return this.$infoCardsData;
    }

    private setDictionaryData(selectedAge?: number): void {
        this.infoFieldsTranslate.pipe(take(1)).subscribe((translate) => {
            this.$userData.pipe(take(1)).subscribe((userData) => {
                this.infoCardsDictionary.next([
                    {
                        value: translate.monthlyPension.title || 'translate is missing',
                        key: 'monthlyPension',
                        info: 'cabinet.home.infoField.monthlyPension.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.expectedPensionAssets.title || 'translate is missing',
                        key: `benefits.retirement.projections.${selectedAge}.capital`,
                        info: 'cabinet.home.infoField.expectedPensionAssets.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.expectedAnnualPension.title || 'translate is missing',
                        key: `benefits.retirement.projections.${selectedAge}.pension`,
                        info: 'cabinet.home.infoField.expectedAnnualPension.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.conversationRate.title || 'translate is missing',
                        key: `benefits.retirement.projections.${selectedAge}.conversionRate`,
                        info: 'cabinet.home.infoField.conversationRate.tooltip',
                        unit: '%',
                    },
                    {
                        value: translate.disabilityPension.title || 'translate is missing',
                        key: 'benefits.disability.beneficiary',
                        info: 'cabinet.home.infoField.disabilityPension.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.annualPensionOfEachChildDisability.title || 'translate is missing',
                        key: 'benefits.disability.child',
                        info: 'cabinet.home.infoField.annualPensionOfEachChildDisability.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.spousePension.title || 'translate is missing',
                        key: 'benefits.death.spouse',
                        info: 'cabinet.home.infoField.spousePension.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.sumPayableAtDeath.title || 'translate is missing',
                        key: 'benefits.death.capital',
                        info: 'cabinet.home.infoField.sumPayableAtDeath.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.annualPensionEachOrphan.title || 'translate is missing',
                        key: 'benefits.death.orphan',
                        info: 'cabinet.home.infoField.annualPensionEachOrphan.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.annualPensionOfEachCompeteOrhan.title || 'translate is missing',
                        key: 'benefits.death.completeOrphan',
                        info: 'cabinet.home.infoField.annualPensionOfEachCompeteOrhan.tooltip',
                        unit: 'CHF',
                    },
                    {
                        value: translate.amountOfVestedBenefits.title || 'translate is missing',
                        key: 'benefits.vestedBenefits.amount',
                        info: 'cabinet.home.infoField.amountOfVestedBenefits.tooltip',
                        unit: 'CHF',
                    },
                ]);
            });
        });
    }

    private setupLocaleChangeHandler(): void {
        this.pensionFundDataSubscription.add(
            this.localeService.$langChange.subscribe(() => {
                this.setDictionaryData(this.selectedAgeNumber);
            })
        );
    }

    private setupSelectedAgeChangeHandler(): void {
        this.pensionFundDataSubscription.add(
            this.selectedAge.asObservable().subscribe((selectedAge) => {
                this.selectedAgeNumber = selectedAge;
                this.setDictionaryData(selectedAge);
            })
        );
    }

    private initBarChartData(insurances: Insurances[]): void {
        this.pensionFundDataSubscription.add(
            this.$infoCardsDictionary.subscribe((dictionary) => {
                this.$userData.pipe(take(1)).subscribe((userData) => {
                    const getPensionDataValueField = (
                        insurance: Insurances,
                        fieldName: 'pension' | 'capital'
                    ): BarChartDataItemValue => {
                        const expectedValue = get(
                            insurance,
                            `benefits.retirement.projections.${this.selectedAge.value}.${fieldName}`
                        );
                        // some magic stuff happens here somehow if its 0 the green thingy gets shown if its a number other then 0 it doesn't
                        const currentValue = 0; /*get(
                            insurance,
                            `benefits.retirement.projections.${
                                Number.parseInt(userData.age.toString(), 10) - 56
                            }.${fieldName}`
                        );*/

                        return expectedValue && currentValue
                            ? {
                                  expected: expectedValue,
                                  current: currentValue,
                              }
                            : expectedValue || currentValue;
                    };

                    const setYearlyPensionData = (insurances: Insurances[]): BarChartData => {
                        return {
                            blockTitle: 'cabinet.home.graphic.yearlyPension',
                            dataList: [
                                ...insurances.map((insurance) => {
                                    return {
                                        title: insurance.foundationId,
                                        bgColor: '#05473a',
                                        value: getPensionDataValueField(insurance, 'pension'),
                                    };
                                }),
                            ],
                        };
                    };
                    const setAssetsData = (insurance: Insurances[]): BarChartData => {
                        return {
                            blockTitle: 'cabinet.home.graphic.assets',
                            dataList: [
                                ...insurance.map((insurance) => {
                                    return {
                                        title: insurance.foundationId,
                                        bgColor: '#05473a',
                                        value: getPensionDataValueField(insurance, 'capital'),
                                    };
                                }),
                            ],
                        };
                    };
                    this.barChartData.next([setYearlyPensionData(insurances), setAssetsData(insurances)]);
                });
            })
        );
    }
}
