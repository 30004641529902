export enum TransactionType {
    EMPLOYER_ADMISSION = 'EMPLOYER_ADMISSION',
    EMPLOYER_TERMINATION = 'EMPLOYER_TERMINATION',
    EMPLOYER_MUTATION_SALARY_NEW = 'EMPLOYER_MUTATION_SALARY_NEW',
    EMPLOYER_MUTATION_SALARY_DELETION = 'EMPLOYER_MUTATION_SALARY_DELETION',
    EMPLOYEE_MUTATION_ADDRESS = 'EMPLOYEE_MUTATION_ADDRESS',
    EMPLOYEE_PURCHASE = 'EMPLOYEE_PURCHASE',
    EMPLOYEE_ACCOUNT_ACTIVATION = 'EMPLOYEE_ACCOUNT_ACTIVATION',
    EMPLOYER_MUTATION_SALARY = 'EMPLOYER_MUTATION_SALARY',
    EMPLOYER_MUTATION_ADDRESS = 'EMPLOYER_MUTATION_ADDRESS',
    BOARDMEMBER_MUTATION_ADDRESS = 'BOARDMEMBER_MUTATION_ADDRESS',
}
