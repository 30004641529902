import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map, Observable } from 'rxjs';
import { GlobalEventsService } from 'src/app/services/global/global-events-service';

@Injectable({
    providedIn: 'root',
})
export class CabinetGuard implements CanActivate {
    constructor(private globalEventsService: GlobalEventsService) {}

    canActivate(): boolean {
        const hasCabinet = sessionStorage.getItem('hasCabinet');
        return hasCabinet === 'true' ? true : false;
    }
}
