import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CustomConfigurationService } from '../services/custom-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class HasCmsGuard implements CanActivate {
    constructor(private customConfigurationService: CustomConfigurationService) {}

    canActivate(): boolean {
        return this.customConfigurationService.getCMSConfig();
    }
}
