<mat-label>{{
    title !== ''
        ? title
        : ((isBirth ? 'cabinet.profile.personal.dateOfBirth' : 'cabinet.purchase.firstTab.dropdownFirst.fieldName')
          | translate)
}}</mat-label>
<mat-form-field
    class="datepicker"
    [ngClass]="{ birthdate: isBirth, light: (1 | colourTheme) === false }"
    appearance="outline"
>
    <input
        matInput
        [matDatepicker]="picker"
        [ngStyle]="{ display: currentDate === null ? 'block' : 'none' }"
        [formControl]="formControl"
        [value]="currentDate"
    />
    <!-- <input matInput type="text" [formControl]="formControl" readonly [value]="" /> -->
    {{ (currentDate !== null ? currentDate : currentValue) | date : 'dd.MM.yyyy' }}
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
